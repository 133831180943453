import React from 'react';
import LayoutSecondary from '../components/layout/LayoutSecondary';
import CompanyAddress from '../components/CompanyAddress';
import Card from '../components/Card';

const Success = () => (
  <LayoutSecondary>

  <div className="max-w-4xl mx-auto p-6">
    <header className="mb-6">
      <h1 className="text-3xl font-bold text-gray-900">Enhancing Security at Punch-in</h1>
      <h2 className="text-2xl font-bold text-gray-400">Essential Features for Modern Applications</h2>
      <p className="mt-2 text-gray-600">A comprehensive guide to implementing robust security measures for applications.</p>
    </header>

    <section className="space-y-8">

      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-900">1. Encrypted Passwords</h2>
        <p className="mt-2 text-gray-700">
          Storing passwords securely is a fundamental aspect of application security. Password breaches can lead to devastating consequences, making encryption a non-negotiable feature.
        </p>
        <h3 className="mt-4 text-lg font-semibold text-gray-800">Best Practices for Password Encryption</h3>
        <ul className="mt-2 list-disc pl-6 text-gray-700">
          <li>Use strong hashing algorithms like <span className="font-mono">bcrypt</span>, <span className="font-mono">PBKDF2</span>, or <span className="font-mono">Argon2</span>.</li>
          <li>Salt passwords to ensure unique hashes.</li>
          <li>Periodically rehash passwords with updated algorithms.</li>
        </ul>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-900">2. State-of-the-Art TLS Encryption</h2>
        <p className="mt-2 text-gray-700">
          Transport Layer Security (TLS) ensures data integrity, confidentiality, and authentication, preventing eavesdropping and man-in-the-middle attacks.
        </p>
        <h3 className="mt-4 text-lg font-semibold text-gray-800">TLS Best Practices</h3>
        <ul className="mt-2 list-disc pl-6 text-gray-700">
          <li>Enforce TLS 1.3 for enhanced security and performance.</li>
          <li>Use strong cipher suites such as <span className="font-mono">AES-256-GCM</span> or <span className="font-mono">ChaCha20-Poly1305</span>.</li>
          <li>Enable HTTP Strict Transport Security (HSTS).</li>
          <li>Regularly update certificates using trusted CAs.</li>
        </ul>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-900">3. Separate Environments</h2>
        <p className="mt-2 text-gray-700">
          Creating secure environments minimizes the attack surface of your application, protecting sensitive data and reducing exposure to vulnerabilities.
        </p>
        <h3 className="mt-4 text-lg font-semibold text-gray-800">Key Strategies</h3>
        <ul className="mt-2 list-disc pl-6 text-gray-700">
          <li>Apply the Principle of Least Privilege (PoLP).</li>
          <li>Use containerization tools like <span className="font-mono">Docker</span> or <span className="font-mono">Kubernetes</span>.</li>
          <li>Store secrets in environment variables instead of hardcoding.</li>
          <li>Configure secure defaults, such as strong password policies.</li>
          <li>Separated environments allows for great reliability and SLA.</li>
        </ul>
      </div>


    </section>
  </div>

  </LayoutSecondary>
);

export default Success;
